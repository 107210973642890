import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';

import { DiscussionEmbed } from "disqus-react"
import SEO from '../components/seo'

export default function BlogPost({ data }) {
  const { html, frontmatter, excerpt } = data.markdownRemark
  const {
    title,
    id,
    date,
    author,
    categories,
    layout,
    description
  } = frontmatter
  const disqusConfig = {
    shortname: 'nickmccullum',
    config: { identifier: title },
  }
  return (
    <Layout>
      <article className="post" itemscope itemtype="http://schema.org/BlogPosting">
        <header className="post-header">
          <h1 className="post-title" itemprop="name headline">{title}</h1>
          <SEO
            title={title}
            description={ description || excerpt }
          />
          <article className="post">
            <div className="entry" dangerouslySetInnerHTML={{ __html: html }}>
            </div>
            <br />
            <div className="date">
              Written on {date}
            </div>
            {/* <DiscussionEmbed {...disqusConfig} /> */}
          </article>
        </header>
      </article>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        id
        date (formatString: "MMMM Do, YYYY")
        author
        categories
        layout
        description
      }
    }
  }
`
